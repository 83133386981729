import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';

@Injectable()
export class ShipmentCargoMasterHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	uploadCSV(
		partyId: number,
		number: string,
		dateOrderStyle: number,
		request: any
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (partyId!==null && partyId!==undefined) {
			params = params.set('partyId', partyId.toString());
		}
		if (number!==null && number!==undefined) {
			params = params.set('number', number);
		}
		if (dateOrderStyle!==null && dateOrderStyle!==undefined) {
			params = params.set('dateOrderStyle', dateOrderStyle.toString());
		}
		return this.http.post<JsonResourceResponse<ShipmentDetailModel>>('api/v1/shipment/cargo/master', request, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	list(
		partyId: number
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (partyId!==null && partyId!==undefined) {
			params = params.set('partyId', partyId.toString());
		}
		return this.http.get<JsonResourceResponse<ShipmentDetailModel>>('api/v1/shipment/cargo/master', { params, headers: {'Accept': 'application/json'} }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	delete(
		partyId: number,
		number: string
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (partyId!==null && partyId!==undefined) {
			params = params.set('partyId', partyId.toString());
		}
		if (number!==null && number!==undefined) {
			params = params.set('number', number);
		}
		return this.http.delete<JsonResourceResponse<ShipmentDetailModel>>('api/v1/shipment/cargo/master', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentCargoMasterHandlerService
	]
})
export class ShipmentCargoMasterHandlerModule { }
